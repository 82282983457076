body {
  background: #666;;
}
/* リンク */
a {
  color: #339963;
}
a:link {  color:#339963; text-decoration: none; }
a:visited {  color:#339963; text-decoration: none; }
a:active {  color:#339963; text-decoration: none; }
a:hover {  color:#339963; text-decoration: underline; }

h1,
h2,
h3,
h4 {
  line-height: 2.0rem;
}

h1 {
  color: #6abee5;
}

strong {
  color: #6abee5;
}

em {
	font-weight: bold;
	font-style: normal;
}

.img_position {
  text-align: center;
}

.img_caption {
  text-align: right;
}

#whitebase {
  color: #eee;
}

.side-part {
  background-color: #333;
  padding: 1rem;
}

#header {
  #site-logo {
    text-align: center;
  }
  #main-nav {
    font-size: 18px;
    padding-left: 1rem;
    list-style-type: square;
    margin-left: 1rem;
  }
}

.post-menu-box {
  padding-left: 1rem;
  margin-bottom: 1rem;
  ul {
    list-style-type: decimal;
    margin-left: 0;
    padding-left: 1rem
  }
  li {
    margin-bottom: 0.25rem;
  }
}

.main-contents {
  background-color: #444;
  padding: 1rem;
  max-width: 900px;
  line-height: 1.66rem;
  width: 100%;

  h2 {
    background-color: rgba(255,255,255,0.05);
    border-left: 5px solid rgba(255,255,255,0.1);
    color: rgb(223, 219, 219);
    margin: 2rem auto 1rem;
    padding: 0.33rem 0.33rem 0.33rem 1rem;
  }
}

.highlight {
  background-color: rgba(255,255,255,0.1);
}
.gutter {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background-color: rgba(255,255,255,0.1);
}

.blog_head {
  text-align: center;
}

.post {
  border-top: 1px dashed #666;
}

/* 小画面 */
/*@media only screen and (min-width:650px) {
}
*/

/* 中画面 */
@media only screen and (min-width:651px) {
  #whitebase {
    display: -webkit-flex;
    display: flex; /* flex設定 */
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .blog_head {
    text-align: left;
  }
  
  .side-part {
    margin-right: 1rem;
    max-width: 300px;
  }

  .main-contents {
  }
}

/* 大画面 */
@media only screen and (min-width:961px) {
}